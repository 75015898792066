<template>
  <div class="container edititem">
    <div class="header-nav">
      <b-icon-chevron-left @click="back"></b-icon-chevron-left>
      <h2 class="mx-auto">Import Transactions</h2>
    </div>
    <div>
      Import any .OFX file from your bank.
      <br />
      Export as mm/dd/yy for best results.
    </div>
    <br />
    <div>
      <input type="file" @change="handleFileUpload" />
    </div>
    <br />
    <div v-if="isLoading">Loading...</div>
    <div v-if="complete && items.length === 0">No new transactions to import.</div>
    <table class="table" v-if="items.length > 0">
      <thead>
        <tr>
          <th scope="col">Date</th>
          <th scope="col">Description</th>
          <th scope="col">Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.id">
          <td>{{ item.date }}</td>
          <td>{{ item.description }}</td>
          <td>{{ item.amount }}</td>
        </tr>
      </tbody>
    </table>
    <div class="flex">
<!--      <button
        type="button"
        class="btn btn-primary btn-block"
        :disabled="isLoading"
        @click="checkForDuplicates"
      >
        Check Duplicates
      </button>-->
      <button
        type="button"
        class="btn btn-primary btn-block"
        :disabled="isLoading || !complete || items.length === 0"
        @click="importTransactions"
      >
        Import
      </button>
    </div>
  </div>
</template>

<script>
import Data from "../data/supabaseActions";
//import Papa from "papaparse";
import xmlParser from 'fast-xml-parser';
import _ from 'lodash';

export default {
  name: "ImportTransactions",
  data() {
    return {
      isLoading: false,
      items: [],
      complete: false
    };
  },
  methods: {
    async checkForDuplicates() {
      this.isLoading = true;
      this.items = await Data.checkForDuplicates(
        this.items,
        this.$route.query.account
      );
      this.isLoading = false;
    },
    async importTransactions() {
      this.isLoading = true;
      const { data, error } = await Data.importTransactions(this.items, this.$route.query.account);
      console.log(data, error)
      if (!error) {
        this.isLoading = false;
        this.back();
      } else {
        alert(error);
      }
    },
    back() {
      this.$router.push({
        name: "TransactionsList",
        query: { account: this.$route.query.account },
      });
    },
    handleFileUpload(event) {
      try {

      this.isLoading = true;
      const file = event.target.files[0];

      const reader = new FileReader();

      const parser = new xmlParser.XMLParser();

      reader.onload = () => {
        const jsonObj = parser.parse(reader.result, { ignoreAttributes: false, attributeNamePrefix: '', arrayMode: true, });
        const transactions = this.extractTransactions(jsonObj);

        for (let i = 1; i < transactions.length; i++) {
          let item = {
            date: transactions[i].DTPOSTED,
            description: transactions[i].NAME,
            amount: transactions[i].TRNAMT,
            accountid: this.$route.query.account,
          };
          //add the item to the items array
          this.items.push(item);
        }
      };

      reader.readAsText(file);

      this.checkForDuplicates();

      this.complete = true;

      } catch (err) {
        alert(err.message);
      }

      this.isLoading = false;


    },
    extractTransactions(ofxData) {
      const transactionPath = ['OFX', 'BANKMSGSRSV1', 'STMTTRNRS', 'STMTRS', 'BANKTRANLIST', 'STMTTRN'];
      return _.get(ofxData, transactionPath, []);
    },
  },
};
</script>
