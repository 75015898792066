import Vue from "vue";
import App from "./App.vue";
import Vuex from "vuex";
import store from "./store/store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

// Import Bootstrap and BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import '@/assets/style.css';

Vue.config.productionTip = false;

// Use Vuex to store the user session
Vue.use(Vuex);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

new Vue({
  store,
  render: (h) => h(App),
}).$mount("#app");
