<template>
    <div class="container loginview"> 
      <div class="row justify-content-center">
        <div class="col-md-6">
          <h2 class="mb-4">Login</h2>
          <form>
            <div class="form-group">
              <label for="email">Email</label>
              <input type="email" id="email" class="form-control" v-model="email" required>
            </div>
            <div class="form-group">
              <label for="password">Password</label>
              <input type="password" id="password" class="form-control" v-model="password" required>
            </div>
            <p v-if="isLoading">Loading...</p>
            <button type="submit" class="btn btn-primary btn-block mb-2" :disabled="isLoading" @click.prevent="login">Login</button>
            <button type="submit" class="btn btn-secondary btn-block mb-2" :disabled="isLoading" @click.prevent="loginAsTest">Login as test user</button>
            <!-- <button type="button" class="btn btn-secondary btn-block" :disabled="true" @click.prevent="register">Register</button> -->
          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
import SpinnerView from './common/SpinnerView.vue';

  export default {
    name: "LoginView",
    comments: {
      SpinnerView
    },
    data() {
      return {
        email: "",
        password: "",
        isLoading: false
      };
    },
    methods: {
      login() {
        this.isLoading = true;
        this.$store.dispatch("login", {
          email: this.email,
          password: this.password,
        }).then(() => {
            this.isLoading = false
        }
        );
      },
      loginAsTest() {
        this.isLoading = true;
        this.$store.dispatch("login", {
          email: 'testuser@gmail.com',
          password: 'testuser',
        }).then(() => {
            this.isLoading = false
        }
        );
      },
      register() {
        this.isLoading = true;

        this.$store.dispatch("register", {
          email: this.email,
          password: this.password,
        }).then(() => {
            this.isLoading = false
        }
        );
      }
    },
  };
  </script>
  
  <style scoped>
  .login {
    max-width: 400px;
    margin: 0 auto;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  label {
    display: block;
    margin-bottom: 0.5rem;
  }
  
  input[type="email"],
  input[type="password"] {
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
  