//build a vue page that holds the users transactions
<template>
  <div class="container">
    <div class="header-nav">
      <b-icon-chevron-left @click="back"></b-icon-chevron-left>
      <h2 class="mx-auto mb-4">Transactions</h2>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Date</th>
          <th scope="col">Description</th>
          <th scope="col">Amount</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.id">
          <td>
            {{ item.date.toLocaleString().substring(0, 10) }}
          </td>
          <td>
            {{ item.description }}
          </td>
          <td>
            {{
              item.amount.toLocaleString("en-US", {
                style: "currency",
                currency: "AUD",
              })
            }}
          </td>
          <td>
            <!-- click and make a function that deletes the saveTransactionDetails -->
            <button
              type="button"
              class="btn btn-danger"
              :disabled="isLoading"
              @click="deleteTransaction(item.transactionid)"
            >
              X
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import Data from "../data/supabaseActions";

export default {
  name: "ViewUserTransactions",
  data() {
    return {
      isLoading: false,
      items: [],
    };
  },
  methods: {
    async getItems() {
      await Data.getUserTransactions(this.$route.query.user).then(
        (response) => {
          this.items = response;
        }
      );
    },
    async deleteTransaction(transactionid) {
      await Data.deleteTransacitonDetail(transactionid).then(() => {
        this.getItems();
      });
    },
    back() {
      this.$router.push({
        name: "AccountsList",
      });
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>
