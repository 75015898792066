import Vue from "vue";
import VueRouter from "vue-router";
import SplitTransaction from "../components/SplitTransaction.vue";
import AddAccount from "../components/AddAccount.vue";
import AccountsList from "../components/AccountsList.vue";
import LoginView from "../components/LoginView.vue";
import TransactionList from "../components/TransactionsList.vue";
import AddNewTransaction from "../components/AddNewTransaction.vue";
import ImportTransactions from "../components/ImportTransactions.vue";
import ViewUserTransactions from "../components/ViewUserTransactions.vue";
import AddUser from "../components/AddUser.vue";
import store from "../store/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "AccountsList",
    component: AccountsList,
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/logout",
    name: "Logout",
    beforeEnter: (to, from, next) => {
      store.dispatch("logout");
      next("/login");
    },
  },
  {
    path: "/account/",
    name: "TransactionsList",
    component: TransactionList,
  },
  {
    path: "/split/",
    name: "SplitTransaction",
    component: SplitTransaction,
  },
  {
    path: "/add",
    name: "AddAccount",
    component: AddAccount,
  },
  {
    path: "/add",
    name: "AddNewTransaction",
    component: AddNewTransaction,
  },
  {
    path: "/adduser",
    name: "AddUser",
    component: AddUser,
  },
  {
    path: "/import",
    name: "ImportTransactions",
    component: ImportTransactions,
  },
  {
    path: "/view",
    name: "ViewUserTransactions",
    component: ViewUserTransactions,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !localStorage.getItem("isLoggedIn")) {
    console.log(localStorage.getItem("isLoggedIn"));
    next("/login");
  } else {
    next();
  }
});

export default router;
