<template>
  <div class="container additem">
    <h2 class="mb-4">Add User</h2>
    <form>
      <div class="form-group">
        <label for="name">Name</label>
        <input type="text" class="form-control" id="name" v-model="user.name" />
      </div>
      <div class="form-group">
        <label for="phone">Phone Number</label>
        <input
          type="text"
          class="form-control"
          id="phone"
          placeholder="04xxxxxxxx"
          v-model="user.phonenumber"
        />
      </div>
      <p v-if="isLoading">Adding...</p>
      <button type="button" class="btn btn-primary" @click="addUser">
        Add
      </button>
    </form>
  </div>
</template>

<script>
import Data from "../data/supabaseActions";

export default {
  name: "AddUser",
  data() {
    return {
      isLoading: false,
      user: {
        name: "",
        phonenumber: "",
        balance: 0,
      },
    };
  },
  methods: {
    async addUser() {
      this.isLoading = true;
      console.log(this.item);
      await Data.addUser(this.user).then(() => {
        this.isLoading = false;
        this.$router.push({ name: "AccountsList" });
      });
    },
  },
};
</script>
