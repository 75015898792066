var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"header-nav"},[_c('b-icon-chevron-left',{on:{"click":_vm.back}}),_c('h2',{staticClass:"mx-auto"},[_vm._v("Transactions")])],1),_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter),expression:"filter"}],staticClass:"form-select w-100 p-2",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.filter=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.filterTransactions]}},[_c('option',{attrs:{"value":"true"}},[_vm._v("Reconciled Transactions")]),_c('option',{attrs:{"value":"false"}},[_vm._v("Unreconciled Transactions")])])]),_c('table',{staticClass:"table"},[_vm._m(0),_c('tbody',_vm._l((_vm.items),function(item){return _c('tr',{key:item.id},[_c('td',[_c('router-link',{staticClass:"link-primary",attrs:{"to":{
              name: 'SplitTransaction',
              query: {
                transaction: item.id,
              },
            }}},[_vm._v(_vm._s(item.date.toLocaleString().substring(0, 10)))])],1),_c('td',[_vm._v(" "+_vm._s(item.description)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.amount.toLocaleString("en-US", { style: "currency", currency: "AUD", }))+" ")]),_c('td',[(item.reconciled)?_c('span',{staticClass:"text-success"},[_vm._v("✔")]):_vm._e()])])}),0)]),_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.my-collapse",modifiers:{"my-collapse":true}}],staticClass:"floating-plus-button",attrs:{"variant":"primary"}},[_vm._v(" + ")]),_c('b-collapse',{staticClass:"floating-collapse",attrs:{"id":"my-collapse"}},[_c('router-link',{staticClass:"btn btn-primary btn-xl",attrs:{"to":{
        name: 'ImportTransactions',
        query: { account: this.$route.query.account },
      }}},[_vm._v("Import")]),_c('router-link',{staticClass:"btn btn-primary btn-xl",attrs:{"to":{
        name: 'AddNewTransaction',
        query: { account: this.$route.query.account },
      }}},[_vm._v("Add")])],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"date-column",attrs:{"scope":"col"}},[_vm._v("Date")]),_c('th',{staticClass:"description-column",attrs:{"scope":"col"}},[_vm._v("Description")]),_c('th',{staticClass:"amount-column",attrs:{"scope":"col"}},[_vm._v("Amount")]),_c('th',{staticClass:"complete-column",attrs:{"scope":"col"}},[_vm._v("X")])])])
}]

export { render, staticRenderFns }