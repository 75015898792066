import { supabase } from "./supabase";

class Data {
  async getAllAccounts() {
    const { data, error } = await supabase.from("account").select();
    if (error) {
      console.log(error);
    }
    return data;
  }

  async addAccount(account) {
    const { data, error } = await supabase.from("account").insert(account);
    if (error) {
      console.log(error);
    }
    return data;
  }

  async getAllUsers() {
    const { data, error } = await supabase.from("users").select();
    if (error) {
      console.log(error);
    }
    return data;
  }

  async getTransactions(accountId, reconciled) {
    const { data, error } = await supabase
      .from("transactions")
      .select()
      .order("date", { ascending: false })
      .eq("accountid", accountId)
      .eq("reconciled", reconciled);
    if (error) {
      console.log(error);
    }
    return data;
  }

  async getTransaction(transacionId) {
    const { data, error } = await supabase
      .from("transactions")
      .select()
      .eq("id", transacionId);
    if (error) {
      console.log(error);
    }
    return data;
  }

  async saveTransaction(transaction) {
    const { data, error } = await supabase
      .from("transactions")
      .insert(transaction);
    if (error) {
      console.log(error);
    }
    return data;
  }

  async checkForDuplicates(transactions, accountid) {
    const { data: savedTransactions, error: transactionError } = await supabase
      .from("transactions")
      .select()
      .order("date", { ascending: false })
      .eq("accountid", accountid);

    if (transactionError) {
      console.log(transactionError);
      return;
    }

    //check for duplicates in savedTransactions against transactions
    const duplicates = transactions.filter((transaction) => {
      const transactionDateISO = this.convertDateToISO(transaction.date);
      return savedTransactions.some((savedTransaction) => {
        const savedTransactionDate = savedTransaction.date.toString().split('T')[0];
        return (
          savedTransaction.amount === transaction.amount &&
          savedTransaction.description === transaction.description &&
          savedTransactionDate === transactionDateISO
        );
      });
    });

    //remove duplicates from transactions
    transactions = transactions.filter((transaction) => {
      const transactionDateISO = this.convertDateToISO(transaction.date);
      return !duplicates.some((duplicate) => {
        const duplicateDate = this.convertDateToISO(duplicate.date);
        return (
          duplicate.amount === transaction.amount &&
          duplicate.description === transaction.description &&
          duplicateDate === transactionDateISO
        );
      });
    });

    return transactions;
  }

  convertDateToISO(dateStr) {
    if (typeof dateStr !== 'string') {
      // Convert to string if possible, otherwise return an empty string or a default value
      dateStr = dateStr?.toString() || '';
    }
    return dateStr.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
  }

  async importTransactions(transactions, accountid) {
    const { data: savedTransactions, error: transactionError } = await supabase
    .from("transactions")
    .select()
    .order("date", { ascending: false })
    .eq("accountid", accountid);

  if (transactionError) {
    console.log(transactionError);
    return;
  }

  //check for duplicates in savedTransactions against transactions
  const duplicates = transactions.filter((transaction) => {
    const transactionDateISO = this.convertDateToISO(transaction.date);
    return savedTransactions.some((savedTransaction) => {
      const savedTransactionDate = savedTransaction.date.toString().split('T')[0];
      return (
        savedTransaction.amount === transaction.amount &&
        savedTransaction.description === transaction.description &&
        savedTransactionDate === transactionDateISO
      );
    });
  });

  //remove duplicates from transactions
  transactions = transactions.filter((transaction) => {
    const transactionDateISO = this.convertDateToISO(transaction.date);
    return !duplicates.some((duplicate) => {
      const duplicateDate = this.convertDateToISO(duplicate.date);
      return (
        duplicate.amount === transaction.amount &&
        duplicate.description === transaction.description &&
        duplicateDate === transactionDateISO
      );
    });
  });

    const { data, error } = await supabase
      .from("transactions")
      .insert(transactions);
    if (error) {
      return error;
    }
    return data;
  }

  async getSimilarTransactions(amount) {
    const { data, error } = await supabase
      .from("transactions")
      .select()
      .order("date", { ascending: false })
      .eq("amount", amount);
    if (error) {
      console.log(error);
    }
    return data;
  }

  async insertTransactionDetail(transactionId, userId, amount) {
    //get transaction details by transactionid

    const { data: existing } = await supabase
      .from("transactiondetail")
      .select()
      .eq("transactionid", transactionId)
      .eq("userid", userId);

    if (existing !== null && existing.length > 0) {
      //update amount
      const { data, error } = await supabase
        .from("transactiondetail")
        .update({ amount: amount })
        .eq("id", existing[0].id);

      if (error) {
        console.log(error);
      }
      return data;
    }

    const transactionDetail = {
      amount: amount,
      transactionid: transactionId,
      userid: userId,
    };
    const { error: failure } = await supabase
      .from("transactiondetail")
      .insert(transactionDetail);
    if (failure) {
      console.log(failure);
      return;
    }

    await supabase
      .from("transactions")
      .update({ reconciled: true })
      .eq("id", transactionId);
  }

  async reconcileTransactionToItself(
    selfID,
    otherTransactionID,
    selfAmount,
    otherAmount
  ) {
    await this.insertTransactionDetail(selfID, null, selfAmount);
    await this.insertTransactionDetail(otherTransactionID, null, otherAmount);
  }

  async addUser(user) {
    const { data, error } = await supabase.from("users").insert(user);
    if (error) {
      console.log(error);
    }
    return data;
  }

  async deleteTransaction(transactionId) {
    //select any transaction details for this transaction
    const { data: transactionDetails } = await supabase
      .from("transactiondetail")
      .select()
      .eq("transactionid", transactionId);

    //if there are any transaction details, delete all of them
    if (transactionDetails !== null && transactionDetails.length > 0) {
      for (let i = 0; i < transactionDetails.length; i++) {
        const { error } = await supabase
          .from("transactiondetail")
          .delete()
          .eq("id", transactionDetails[i].id);
        if (error) {
          console.log(error);
        }
      }
    }

    const { data, error } = await supabase
      .from("transactions")
      .delete()
      .eq("id", transactionId);
    if (error) {
      console.log(error);
    }
    return data;
  }

  async deleteTransacitonDetail(transacionId) {
    if (
      !confirm(
        "This will delete the transaction detail for all users.  Are you sure you want to continue?"
      )
    ) {
      return;
    }

    //delete the transaction detail
    const { data, error } = await supabase
      .from("transactiondetail")
      .delete()
      .eq("transactionid", transacionId);
    if (error) {
      console.log(error);
    }

    //update the transaction to be unreconciled
    await supabase
      .from("transactions")
      .update({ reconciled: false })
      .eq("id", transacionId);

    return data;
  }

  async getUserTransactions(userId) {
    //call the supabase function get_transaction_details and pass in the userid

    const { data, error } = await supabase.rpc(
      "get_transaction_detail_for_user",
      {
        user_id: userId,
      }
    );

    if (error) {
      console.log(error);
    }
    return data;
  }

  async setUserSession(access_token, refresh_token) {
    const { error } = supabase.auth.setSession({
      access_token,
      refresh_token,
    });

    if (error) {
      console.log(error);
    } else {
      localStorage.setItem("supabase.auth.token", access_token);
      localStorage.setItem("supabase.auth.refreshToken", refresh_token);
      localStorage.setItem("isLoggedIn", true);
    }
  }

  async getUserSession() {
    const { data, error } = supabase.auth.getSession();
    if (error) {
      console.log(error);
    } else {
      this.$store.commit("setLoggedIn", true);
    }
    return data;
  }
}

export default new Data();
