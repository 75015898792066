<template>
  <div class="container additem">
    <h2 class="mb-4">Add Account</h2>
    <form>
      <div class="form-group">
        <label for="name">Name</label>
        <input type="text" class="form-control" id="name" v-model="item.name" />
      </div>
      <div class="form-group">
        <label for="price">Balance</label>
        <input
          type="number"
          class="form-control"
          id="price"
          v-model="item.balance"
        />
      </div>
      <p v-if="isLoading">Adding...</p>
      <button type="button" class="btn btn-primary" @click="addItem">
        Add
      </button>
    </form>
  </div>
</template>

<script>
import Data from "../data/supabaseActions";

export default {
  name: "AddAccount",
  data() {
    return {
      isLoading: false,
      item: {
        name: "",
        balance: 0,
      },
    };
  },
  methods: {
    async addItem() {
      this.isLoading = true;
      console.log(this.item);
      await Data.addAccount(this.item).then(() => {
        this.isLoading = false;
        this.$router.push({ name: "AccountsList" });
      });
    },
  },
};
</script>
