<template>
  <div class="container edititem">
    <div class="header-nav">
      <b-icon-chevron-left @click="back"></b-icon-chevron-left>
      <h2 class="mx-auto mb-4">Add New Transaction</h2>
    </div>
    <form v-if="!isLoading">
      <div class="form-group">
        <label for="name">Description</label>
        <input
          type="text"
          class="form-control"
          id="name"
          v-model="item.description"
        />
      </div>
      <div class="form-group">
        <label for="amount">Amount</label>
        <input
          type="number"
          class="form-control"
          id="amount"
          v-model="item.amount"
        />
      </div>
      <div class="form-group">
        <label for="date">Date</label>
        <flat-pickr class="form-control" v-model="item.date"></flat-pickr>
      </div>
      <button
        type="button"
        class="btn btn-primary"
        :disabled="isLoading"
        @click="save"
      >
        Save
      </button>
    </form>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Data from "../data/supabaseActions";

export default {
  name: "AddNewTransaction",
  components: {
    flatPickr,
  },
  data() {
    return {
      isLoading: false,
      item: {
        description: "",
        amount: "",
        date: new Date(),
        accountid: this.$route.query.account,
      },
    };
  },
  methods: {
    async save() {
      if (this.item.description === "") {
        alert("Please enter a description");
        return;
      }
      if (this.item.amount === "") {
        alert("Please enter an amount");
        return;
      }

      this.isLoading = true;
      await Data.saveTransaction(this.item);
      this.isLoading = false;
      this.back();
    },
    back() {
      this.$router.push({
        name: "TransactionsList",
        query: { account: this.item.accountid },
      });
    },
  },
};
</script>
