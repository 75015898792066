//build a vue page that holds the transactions list
<template>
  <div class="container">
    <div class="header-nav">
      <b-icon-chevron-left @click="back"></b-icon-chevron-left>
      <h2 class="mx-auto">Transactions</h2>
    </div>
    <div>
      <select
        class="form-select w-100 p-2"
        v-model="filter"
        @change="filterTransactions"
      >
        <option value="true">Reconciled Transactions</option>
        <option value="false">Unreconciled Transactions</option>
      </select>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col" class="date-column">Date</th>
          <th scope="col" class="description-column">Description</th>
          <th scope="col" class="amount-column">Amount</th>
          <th scope="col" class="complete-column">X</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.id">
          <td>
            <router-link
              :to="{
                name: 'SplitTransaction',
                query: {
                  transaction: item.id,
                },
              }"
              class="link-primary"
              >{{ item.date.toLocaleString().substring(0, 10) }}</router-link
            >
          </td>
          <td>
            {{ item.description }}
          </td>
          <td>
            {{
              item.amount.toLocaleString("en-US", {
                style: "currency",
                currency: "AUD",
              })
            }}
          </td>
          <td>
            <span v-if="item.reconciled" class="text-success">✔</span>
          </td>
        </tr>
      </tbody>
    </table>
    <b-button
      v-b-toggle.my-collapse
      variant="primary"
      class="floating-plus-button"
    >
      +
    </b-button>
    <b-collapse id="my-collapse" class="floating-collapse">
      <router-link
        :to="{
          name: 'ImportTransactions',
          query: { account: this.$route.query.account },
        }"
        class="btn btn-primary btn-xl"
        >Import</router-link
      >
      <router-link
        :to="{
          name: 'AddNewTransaction',
          query: { account: this.$route.query.account },
        }"
        class="btn btn-primary btn-xl"
        >Add</router-link
      >
    </b-collapse>
  </div>
</template>
<script>
import Data from "../data/supabaseActions";

export default {
  name: "TransactionsList",
  data() {
    return {
      items: [],
      filter: false,
    };
  },
  methods: {
    async getItems() {
      Data.getTransactions(this.$route.query.account, this.filter).then(
        (response) => {
          this.items = response;
        }
      );
    },
    back() {
      this.$router.push({
        name: "AccountsList",
      });
    },
    filterTransactions() {
      this.getItems();
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>
<style scoped>
.floating-plus-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 24px;
}

.floating-collapse {
  position: fixed;
  bottom: 90px;
  right: 20px;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: auto;
  display: flex;
  flex-direction: column;

  .btn-xl {
    /* Apply xl size to buttons */
    padding: 1rem 2rem;
    font-size: 1.5rem;
    line-height: 1.33;
    border-radius: 0.3rem;
    margin-bottom: 5px;
  }
}

.table td,
.table th {
  word-wrap: break-word; /* Older browsers */
  overflow-wrap: break-word; /* Modern browsers */
}

.table {
  table-layout: fixed;
  padding: 0.25rem;
}

.description-column {
  width: 45%; /* Adjust this value to your liking */
}

.amount-column {
  width: 30%; /* Adjust this value to your liking */
}

.date-column {
  width: 25%; /* Adjust this value to your liking */
}

.complete-column {
  width: 5%; /* Adjust this value to your liking */
}
</style>
