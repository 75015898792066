<template>
  <div class="container edititem">
    <div class="header-nav">
      <b-icon-chevron-left @click="back"></b-icon-chevron-left>
      <h2 class="mx-auto mb-4">Split Transaction</h2>
    </div>
    <form v-if="!isLoading">
      <div class="card mb-4" style="border: none">
        <div class="card-body">
          <h5 class="card-title">{{ item.description }}</h5>
          <p class="card-text">
            Amount: ${{ parseFloat(item.amount).toFixed(2) }}<br />
            Date: {{ item.date.toLocaleString().substring(0, 10) }}
          </p>
          <a
            :href="googleSearchLink(item.description)"
            target="_blank"
            class="btn btn-primary btn-block btn-sm mb-1"
            >Google Search</a
          >
          <button
            type="button"
            class="btn btn-danger btn-block btn-sm"
            :disabled="isLoading"
            @click="deleteTransaction"
          >
            Delete Transaction
          </button>
        </div>
      </div>
      <div class="form-group">
        <label for="user" class="form-label">Reconcile By:</label>
        <select
          class="form-control"
          id="user"
          v-model="selectedOption"
          @change="updateSelectedOption"
        >
          <option
            v-for="option in splitOptions"
            :key="option.id"
            :value="option.id"
          >
            {{ option.name }}
          </option>
        </select>
      </div>

      <div v-if="selectedOption === 1" class="form-group">
        <label for="user">50%</label>
        <select class="form-control" id="user" v-model="primaryUser">
          <option v-for="user in users" :key="user.id" :value="user.id">
            {{ user.name }}
          </option>
        </select>
        <label for="user">50%</label>
        <select class="form-control" id="user" v-model="secondaryUser">
          <option v-for="user in users" :key="user.id" :value="user.id">
            {{ user.name }}
          </option>
        </select>
      </div>

      <div v-if="selectedOption === 2" class="form-group">
        <label for="user">Primary</label>
        <select class="form-control" id="user" v-model="primaryUser">
          <option v-for="user in users" :key="user.id" :value="user.id">
            {{ user.name }}
          </option>
        </select>
        <label for="primaryAmount">Amount</label>
        <input
          type="number"
          class="form-control"
          id="primaryAmount"
          v-model="primaryAmount"
        />
        <label for="user">Secondary</label>
        <select class="form-control" id="user" v-model="secondaryUser">
          <option v-for="user in users" :key="user.id" :value="user.id">
            {{ user.name }}
          </option>
        </select>
        <label for="secondaryAmount">Amount</label>
        <input
          type="number"
          class="form-control"
          id="secondaryAmount"
          v-model="secondaryAmount"
        />
        <div>Remaining: {{ remainingAmount }}</div>
      </div>

      <div v-if="selectedOption === 3" class="form-group">
        <label for="user">Select Transaction</label>
        <select class="form-control" id="user" v-model="selectedTransactionId">
          <option
            v-for="transaction in transactions"
            :key="transaction.id"
            :value="transaction.id"
          >
            {{ transaction.description }} - {{ transaction.amount }}
          </option>
        </select>
      </div>

      <div v-if="selectedOption === 4" class="form-group">
        <label for="user">Select User</label>
        <select class="form-control" id="user" v-model="primaryUser">
          <option v-for="user in users" :key="user.id" :value="user.id">
            {{ user.name }}
          </option>
        </select>
      </div>

      <button
        type="button"
        class="btn btn-primary btn-block"
        :disabled="isLoading || item.reconciled"
        @click="saveTransactionDetails"
      >
        Reconcile
      </button>
    </form>
  </div>
</template>

<script>
import Data from "../data/supabaseActions";

export default {
  name: "SplitTransaction",
  data() {
    return {
      isLoading: false,
      primaryUser: 1,
      primaryAmount: 0,
      secondaryUser: 2,
      secondaryAmount: 0,
      selectedOption: 1,
      selectedTransactionId: null,
      users: [],
      item: {
        id: "",
        description: "",
        amount: "",
        date: new Date(),
        accountid: "",
        reconciled: false
      },
      transactions: [],
      splitOptions: [
        { id: 1, name: "Split Evenly" },
        { id: 2, name: "Split by Amount" },
        { id: 3, name: "Reconcile To Transaction (same amount)" },
        { id: 4, name: "Assign To User" },
      ],
    };
  },
  computed: {
    remainingAmount() {
      return (
        this.item.amount -
        (parseFloat(this.primaryAmount) + parseFloat(this.secondaryAmount))
      );
    },
  },
  methods: {
    async deleteTransaction() {
      //prompt user if they are sure?
      if (!confirm("Are you sure you want to delete this transaction?")) {
        return;
      }
      this.isLoading = true;
      await Data.deleteTransaction(this.item.id);
      this.isLoading = false;
      this.back();
    },
    googleSearchLink(query) {
      const encodedQuery = encodeURIComponent(query);
      return `https://www.google.com/search?q=${encodedQuery}`;
    },
    async getItem() {
      this.isLoading = true;
      const response = await Data.getTransaction(this.$route.query.transaction);
      this.item = response[0];
      this.isLoading = false;
    },
    async getUsers() {
      this.isLoading = true;
      this.users = await Data.getAllUsers();
      this.selectedUser = this.users[0].id;
      this.isLoading = false;
    },
    async getSimilarTransactions() {
      this.isLoading = true;
      this.transactions = await Data.getSimilarTransactions(this.item.amount * -1);
      this.isLoading = false;
    },
    async updateSelectedOption() {
      if (this.selectedOption === 3) {
        this.getSimilarTransactions();
      }
    },
    async saveTransactionDetails() {
      if (this.selectedOption === 1) {
        this.splitEvenly();
      } else if (this.selectedOption === 2) {
        this.splitByAmount();
      } else if (this.selectedOption === 3) {
        this.reconcileToExistingTransaction();
      } else if (this.selectedOption === 4) {
        this.assignToUser();
      } else if (this.selectedOption === null) {
        alert("Please select a reconcile option");
      } else {
        alert("Please select a reconcile option");
      }
    },
    async splitEvenly() {
      this.isLoading = true;
      await Data.insertTransactionDetail(
        this.item.id,
        this.primaryUser,
        this.item.amount / 2
      );
      await Data.insertTransactionDetail(
        this.item.id,
        this.secondaryUser,
        this.item.amount / 2
      );
      this.isLoading = false;
      this.back();
    },
    async splitByAmount() {
      if (
        parseFloat(this.primaryAmount) + parseFloat(this.secondaryAmount) !==
        this.item.amount
      ) {
        console.log(
          parseFloat(this.primaryAmount) + parseFloat(this.secondaryAmount)
        );
        alert("Amounts do not add up to the total");
        return;
      }

      this.isLoading = true;
      await Data.insertTransactionDetail(
        this.item.id,
        this.primaryUser,
        this.primaryAmount
      );
      await Data.insertTransactionDetail(
        this.item.id,
        this.secondaryUser,
        this.secondaryAmount
      );
      this.isLoading = false;
      this.back();
    },
    async assignToUser() {
      this.isLoading = true;
      await Data.insertTransactionDetail(
        this.item.id,
        this.primaryUser,
        this.item.amount
      );
      this.isLoading = false;
      this.back();
    },
    async reconcileToExistingTransaction() {
      this.isLoading = true;
      await Data.reconcileTransactionToItself(
        this.item.id,
        this.selectedTransactionId,
        this.item.amount,
        //select the transaction.amount from the selectedtransactionid
        this.transactions.find(
          (transaction) => transaction.id === this.selectedTransactionId
        ).amount
      );
      this.isLoading = false;
      this.back();
    },
    back() {
      this.$router.push({
        name: "TransactionsList",
        query: { account: this.item.accountid },
      });
    },
  },
  created() {
    this.getItem();
    this.getUsers();
  },
};
</script>
<style scoped>
/* style each label to be left aligned */
.form-group label {
  text-align: left;
}
</style>
