<template>
  <div class="container">
    <b-overlay :show="isLoading" rounded="sm">
      <div class="header">
        <h2>Accounts</h2>
        <router-link
          :to="{ name: 'AddAccount' }"
          class="btn btn-primary rounded-circle btn-lg"
          >+</router-link
        >
      </div>

      <div v-for="item in items" :key="item.id" :item="item">
        <b-card
          :title="item.name"
          img-src="img/contactless.png"
          img-alt="card"
          img-left
          align="left"
          img-height="100px"
          style="max-width: 60rem; border: none"
          class="mb-2 flex-row clickable"
          @click="handleCardClick(item)"
        >
          <b-card-text style="flex-direction: column">
            Balance:
            {{
              item.balance.toLocaleString("en-US", {
                style: "currency",
                currency: "AUD",
              })
            }}
          </b-card-text>
        </b-card>
      </div>
      <div>
        <!-- sum the total of each users balance with total: $-->
        <div class="text-left mb-2">
          Oustanding:
          {{
            (
              users.reduce((a, b) => a + b.balance, 0) -
              items.reduce((a, b) => a + b.balance, 0)
            ).toLocaleString("en-US", {
              style: "currency",
              currency: "AUD",
            })
          }}
        </div>
      </div>
      <div class="header">
        <h2>Users</h2>
        <router-link
          :to="{ name: 'AddUser' }"
          class="btn btn-primary rounded-circle btn-lg"
          >+</router-link
        >
      </div>
      <div v-for="user in users" :key="user.id" :user="user">
        <b-card
          img-src="img/user.png"
          img-alt="card"
          img-left
          img-height="70px"
          style="max-width: 60rem; border: none"
          class="mb-2 flex-row"
          @click="handleUserCardClick(user)"
        >
          <b-card-sub-title style="text-align: justify">
            {{ user.name }}
          </b-card-sub-title>
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
            "
          >
            <b-card-text style="flex-direction: row; margin-right: auto">
              Balance:
              {{
                user.balance.toLocaleString("en-US", {
                  style: "currency",
                  currency: "AUD",
                })
              }}
            </b-card-text>
            <b-dropdown id="dropdown-1" text="..." variant="light" class="m-md">
              <b-dropdown-item
                :href="generateSmsLink(user.balance, user.phonenumber)"
              >
                SMS Balance
              </b-dropdown-item>
              <b-dropdown-item
                :to="{ name: 'ViewUserTransactions', query: { user: user.id } }"
              >
                View Transactions
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </b-card>
      </div>
      <button class="btn btn-danger btn-block" @click="logout">Logout</button>
    </b-overlay>
  </div>
</template>

<script>
import Data from "../data/supabaseActions";

export default {
  data() {
    return {
      users: [],
      items: [],
      isLoading: true,
    };
  },
  methods: {
    generateSmsLink(balance, phoneNumber) {
      const message = `Please transfer this amount to the credit card: ${balance.toLocaleString(
        "en-US",
        { style: "currency", currency: "AUD" }
      )}`;

      const encodedMessage = encodeURIComponent(message);
      return `sms:${phoneNumber}?body=${encodedMessage}`;
    },
    async getItems() {
      Data.getAllAccounts().then((response) => {
        this.items = response;
      });
    },
    async getUsers() {
      Data.getAllUsers().then((response) => {
        this.users = response;
      });
    },
    logout() {
      this.$store.dispatch("logout");
    },
    handleCardClick(item) {
      this.$router.push({
        name: "TransactionsList",
        query: { account: item.id },
      });
    },
  },
  computed: {},
  async mounted() {
    await this.getItems();
    await this.getUsers();
    this.isLoading = false;
  },
};
</script>

<style scoped>
.dropdown {
  display: inline-block;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-primary {
  margin-left: auto;
}
</style>
