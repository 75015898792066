import Vue from "vue";
import Vuex from "vuex";
import router from "../router/routes";
import { supabase } from "../data/supabase";
import Data from "../data/supabaseActions";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    isLoggedIn: false,
  },
  mutations: {
    setLoggedIn(state, payload) {
      state.isLoggedIn = payload;
    },
  },
  actions: {
    async login({ commit }, payload) {
      const { data, error } = await supabase.auth.signInWithPassword({
        email: payload.email,
        password: payload.password,
      });
      if (error) {
        alert(error.message);
        console.log(error);
        return;
      } else {
        await Data.setUserSession(data.access_token, data.refresh_token);
        commit("setLoggedIn", true);
        localStorage.setItem("isLoggedIn", true);
        router.push("/");
      }
    },
    async logout({ commit }) {
      await supabase.auth.signOut();
      commit("setLoggedIn", false);
      localStorage.removeItem("isLoggedIn");
      router.push("/login");
    },
    async register({ commit }, payload) {
      const { data, error } = await supabase.auth.signUp({
        email: payload.email,
        password: payload.password,
      });
      if (error) {
        console.log(error);
        return;
      } else {
        await Data.setUserSession(data.access_token, data.refresh_token).then(
          () => {
            console.log("session set");
            commit("setLoggedIn", true);
            localStorage.setItem("isLoggedIn", true);
            router.push("/");
          }
        );
      }
    },
  },
});

export default store;
